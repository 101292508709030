exports.components = {
  "component---src-pages-403-tsx": () => import("./../../../src/pages/403.tsx" /* webpackChunkName: "component---src-pages-403-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-infraestrutura-cabeamento-estruturado-index-tsx": () => import("./../../../src/pages/infraestrutura/cabeamento-estruturado/index.tsx" /* webpackChunkName: "component---src-pages-infraestrutura-cabeamento-estruturado-index-tsx" */),
  "component---src-pages-personalize-sua-empresa-desenvolvimento-de-apps-index-tsx": () => import("./../../../src/pages/personalize-sua-empresa/desenvolvimento-de-apps/index.tsx" /* webpackChunkName: "component---src-pages-personalize-sua-empresa-desenvolvimento-de-apps-index-tsx" */),
  "component---src-pages-personalize-sua-empresa-desenvolvimento-de-sistemas-index-tsx": () => import("./../../../src/pages/personalize-sua-empresa/desenvolvimento-de-sistemas/index.tsx" /* webpackChunkName: "component---src-pages-personalize-sua-empresa-desenvolvimento-de-sistemas-index-tsx" */),
  "component---src-pages-personalize-sua-empresa-desenvolvimento-de-sites-index-tsx": () => import("./../../../src/pages/personalize-sua-empresa/desenvolvimento-de-sites/index.tsx" /* webpackChunkName: "component---src-pages-personalize-sua-empresa-desenvolvimento-de-sites-index-tsx" */),
  "component---src-pages-sistemas-integrum-administracao-imobiliaria-tsx": () => import("./../../../src/pages/sistemas-integrum/administracao-imobiliaria.tsx" /* webpackChunkName: "component---src-pages-sistemas-integrum-administracao-imobiliaria-tsx" */),
  "component---src-pages-sobre-a-integrum-index-tsx": () => import("./../../../src/pages/sobre-a-integrum/index.tsx" /* webpackChunkName: "component---src-pages-sobre-a-integrum-index-tsx" */),
  "component---src-pages-suporte-em-ti-index-tsx": () => import("./../../../src/pages/suporte-em-ti/index.tsx" /* webpackChunkName: "component---src-pages-suporte-em-ti-index-tsx" */),
  "component---src-pages-suporte-em-ti-para-sua-casa-index-tsx": () => import("./../../../src/pages/suporte-em-ti/para-sua-casa/index.tsx" /* webpackChunkName: "component---src-pages-suporte-em-ti-para-sua-casa-index-tsx" */),
  "component---src-pages-suporte-em-ti-suporte-hibrido-index-tsx": () => import("./../../../src/pages/suporte-em-ti/suporte-hibrido/index.tsx" /* webpackChunkName: "component---src-pages-suporte-em-ti-suporte-hibrido-index-tsx" */),
  "component---src-pages-suporte-em-ti-suporte-local-index-tsx": () => import("./../../../src/pages/suporte-em-ti/suporte-local/index.tsx" /* webpackChunkName: "component---src-pages-suporte-em-ti-suporte-local-index-tsx" */)
}

